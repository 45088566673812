import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
} from "firebase/auth";
import { staffUserEndpointMap } from "services/definitions/endpoint.definition";
import { FirebaseAuthErrorCode } from "services/models/firebase";
import { ServiceResult } from "services/models/service";
import { sendRequest } from "services/utils/api";
import { getFirebaseUser, handleFirebaseError } from "services/utils/firebase";

export class AuthService {
  static async login(
    email: string,
    password: string,
  ): Promise<ServiceResult<UserCredential, FirebaseAuthErrorCode>> {
    try {
      return {
        result: await signInWithEmailAndPassword(getAuth(), email, password),
      };
    } catch (error) {
      console.error(error);
      return { error: handleFirebaseError(error) };
    }
  }

  static async signUp(
    email: string,
    password: string,
    inviteToken?: string,
  ): Promise<ServiceResult<void, FirebaseAuthErrorCode>> {
    return await sendRequest<
      void,
      { email: string; password: string },
      FirebaseAuthErrorCode
    >({
      endpointInfo: staffUserEndpointMap.signUp,
      data: { email, password },
      params: { inviteToken },
      useTokenInHeaders: false,
    });
  }

  static async logout() {
    signOut(getAuth());
  }

  static async resetPassword(email: string) {
    try {
      return {
        result: await sendPasswordResetEmail(getAuth(), email),
      };
    } catch (error) {
      return { error: handleFirebaseError(error) };
    }
  }

  static async reauthenticateCurrentUserWithPassword(
    password: string,
  ): Promise<ServiceResult<UserCredential, FirebaseAuthErrorCode>> {
    try {
      const currentUser = getFirebaseUser();
      if (!currentUser?.email) {
        return {
          error: {
            message: "auth/null-user",
            detailMessage: "User is required for this operation",
          },
        };
      }

      const credential = EmailAuthProvider.credential(
        currentUser.email,
        password,
      );

      return {
        result: await reauthenticateWithCredential(currentUser, credential),
      };
    } catch (error) {
      return { error: handleFirebaseError(error) };
    }
  }
}
