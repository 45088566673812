import { getAuth, Unsubscribe, User } from "firebase/auth";
import { SalonCreateDto } from "models/salon/salon-create.dto";
import { SalonDto, SalonOverviewDto } from "models/salon/salon.dto";
import {
  SalonModifyBasicInfoDto,
  SalonModifyDto,
} from "services/apis/salon/salon.dto";
import { salonEndpointMap } from "services/definitions/endpoint.definition";
import { SalonErrorMessage } from "services/models/error";
import { FirebaseAuthErrorCode } from "services/models/firebase";
import { ServiceResult } from "services/models/service";
import { sendRequest } from "services/utils/api";
import { handleFirebaseError } from "services/utils/firebase";

export class SalonService {
  static async getSalonInfo(
    salonId: string,
  ): Promise<ServiceResult<SalonDto, SalonErrorMessage>> {
    return sendRequest<SalonDto, any, SalonErrorMessage>({
      endpointInfo: salonEndpointMap.getSalon,
      extraPath: [salonId],
    });
  }

  static async getSalonOverviewForGuest(
    salonId: string,
  ): Promise<ServiceResult<SalonOverviewDto, SalonErrorMessage>> {
    return sendRequest<SalonOverviewDto, any, SalonErrorMessage>({
      endpointInfo: salonEndpointMap.getSalon,
      extraPath: [salonId, "guest"],
      useTokenInHeaders: false,
    });
  }

  static async createSalon(
    data: SalonCreateDto,
  ): Promise<ServiceResult<SalonDto, SalonErrorMessage>> {
    return sendRequest<SalonDto, SalonCreateDto, SalonErrorMessage>({
      endpointInfo: salonEndpointMap.createSalon,
      data,
    });
  }

  static async modifySalonBasicInfo(salonId: string, data: SalonModifyDto) {
    return sendRequest<void, SalonModifyBasicInfoDto, SalonErrorMessage>({
      endpointInfo: salonEndpointMap.modifySalonBasicInfo,
      extraPath: [salonId],
      data,
    });
  }

  static async deleteSalon(salonId: string) {
    return sendRequest<void, never, SalonErrorMessage>({
      endpointInfo: salonEndpointMap.deleteSalon,
      extraPath: [salonId],
    });
  }

  // ----
  static getCurrentUser(): User | null {
    return getAuth().currentUser;
  }

  static async onAuthStateChange(
    callback: (user: User | null) => void,
  ): Promise<ServiceResult<Unsubscribe, FirebaseAuthErrorCode>> {
    try {
      return { result: getAuth().onAuthStateChanged(callback) };
    } catch (error) {
      return { error: handleFirebaseError(error) };
    }
  }
}
