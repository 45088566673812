import { PossibleQueryKey } from "data/url/url.data";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Bind local state to query param but only update state with param in initial render
 *
 * @returns [localState, queryValue, setLocalState]
 * */
export function useQueryMatch(
  queryKey: PossibleQueryKey,
  initialValue?: string,
): [
  string | null,
  string | null,
  React.Dispatch<React.SetStateAction<string | null>>,
] {
  const isInitialRender = useRef(true);

  const [localState, setLocalState] = useState(initialValue ?? null);

  const [searchParams, setSearchParams] = useSearchParams();
  const queryValue = useMemo(
    () => searchParams.get(queryKey),
    [searchParams, queryKey],
  );

  /** Update state with query param on first render */
  const isQueryAndStateSame = useMemo(
    () =>
      (localState == null && queryValue == null) || queryValue === localState,
    [localState, queryValue],
  );

  useEffect(() => {
    if (isQueryAndStateSame || (isInitialRender.current && !localState)) {
      return;
    }

    // Update query param based on state
    if (localState == null) {
      searchParams.delete(queryKey);
    } else {
      searchParams.set(queryKey, localState.toString());
    }
    // setTimeout to work around for a race condition issue which would cause
    // infinite loop if we setLocalState immediately on localState changed from
    // outside.
    // TODO find root cause & more proper solution
    setTimeout(() => {
      setSearchParams(searchParams);
      isInitialRender.current = false;
    }, 0);

    // ! Don't put `isQueryAndStateSame` or it will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState, queryKey]);

  /** Update query param with new state */
  useEffect(() => {
    if (isQueryAndStateSame || (isInitialRender.current && !queryValue)) {
      return;
    }

    setTimeout(() => {
      setLocalState(queryValue ?? null);
      isInitialRender.current = false;
    }, 0);

    // ! Don't put `isQueryAndStateSame` or it will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  return [localState, queryValue, setLocalState];
}
