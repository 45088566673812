import { useStoreState } from "stores";
import "./AppHeader.scss";

export const AppHeader = () => {
  const title = useStoreState((state) => state.appHeader.title);
  const content = useStoreState((state) => state.appHeader.content);

  if (!title && !content) {
    return null;
  }

  return (
    <div className="app-header">
      {title && <h1 className="app-header__title">{title}</h1>}
      {content}
    </div>
  );
};
