import { useEffect } from "react";
import { useStoreActions } from "stores";

export const useAuthManager = () => {
  const { setUpAuthListener } = useStoreActions((actions) => actions.auth);

  useEffect(() => {
    setUpAuthListener();
  }, [setUpAuthListener]);

  return null;
};
