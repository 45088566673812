import { Transition } from "@headlessui/react";
import cx from "classnames";
import { AppLogo } from "components/utils/AppLogo";
import { supportLanguageList } from "data/i18n/i18n.data";
import { routeBasePath } from "data/navigation/route-list";
import {
  shouldShowSidebarItem,
  sidebarGroupInfoMap,
  sideBarGroupList,
  sidebarItemInfoMap,
} from "data/navigation/sidebar";
import { Button, Icon } from "ducduchy-react-components";
import { useMediaQuery } from "hooks";
import { MouseEventHandler, PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useStoreActions, useStoreState } from "stores";
import "./Sidebar.scss";

interface SidebarGroupProps {
  title: string;
  expanded: boolean;
}

const SidebarGroup = ({
  title,
  expanded,
  children,
}: PropsWithChildren<SidebarGroupProps>) => {
  return (
    <div className="sidebar__group">
      <h3
        className={cx("sidebar__group-title", {
          "sidebar__group-title--hidden": !expanded,
        })}
      >
        {title}
      </h3>

      <ul className="sidebar__group-list">{children}</ul>
    </div>
  );
};

export const Sidebar = () => {
  const { t } = useTranslation();

  const isSidebarBlank = useStoreState((state) => state.sidebar.isSidebarBlank);

  const isLoggedIn = useStoreState((state) => state.auth.isLoggedIn);
  const userAuthState = useStoreState((state) => state.auth.userAuthState);
  const userSalonRole = useStoreState((state) => state.salon.salonRole);
  const { logout } = useStoreActions((actions) => actions.auth);

  const locale = useStoreState((state) => state.account.locale);
  const setLocale = useStoreActions((actions) => actions.account.setLocale);

  const [active, setActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const salonTier = useStoreState((state) => state.salon.salonTier);

  const isLg = useMediaQuery("lg");
  const onOverlayClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isLg) {
      event.preventDefault();
      event.stopPropagation();
      setActive(false);
    }
  };

  const toggleSidebar = () => {
    setActive(!active);
  };

  return (
    <>
      {!isLg && (
        <Transition
          show={active}
          onClick={onOverlayClick}
          className="sidebar__overlay"
          enter="sidebar__overlay--enter"
          enterFrom="sidebar__overlay--enter-from"
          enterTo="sidebar__overlay--enter-to"
          leave="sidebar__overlay--leave"
          leaveFrom="sidebar__overlay--leave-from"
          leaveTo="sidebar__overlay--leave-to"
        />
      )}

      <button
        className={cx("sidebar__hamburger", {
          "sidebar__hamburger--hide": active,
        })}
        onClick={toggleSidebar}
      >
        <Icon icon={["fas", "bars"]} />
      </button>

      <div
        className={cx("sidebar", {
          "sidebar--active": active,
          "sidebar--expanded": isExpanded || !isLg,
        })}
      >
        <div className="logo-container">
          <AppLogo />
        </div>

        <div className="main-content">
          {!isSidebarBlank &&
            sideBarGroupList
              // Filter out groups that has NO item to show
              .filter((group) => {
                const sidebarItemInfos = sidebarItemInfoMap[group];
                const hasAnyShownItem = sidebarItemInfos.some((item) =>
                  shouldShowSidebarItem(
                    item,
                    userSalonRole,
                    salonTier,
                    userAuthState,
                  ),
                );

                return hasAnyShownItem;
              })
              .map((group) => (
                <SidebarGroup
                  key={group}
                  expanded={isExpanded}
                  title={t(sidebarGroupInfoMap[group].title) as string}
                >
                  {sidebarItemInfoMap[group]
                    .filter((item) =>
                      shouldShowSidebarItem(
                        item,
                        userSalonRole,
                        salonTier,
                        userAuthState,
                      ),
                    )
                    .map(({ icon, pathKey, title }) => (
                      <li className="sidebar__group-item" key={pathKey}>
                        <NavLink
                          to={routeBasePath[pathKey]}
                          className={({ isActive }) =>
                            cx("sidebar__group-item-link sidebar__item", {
                              "sidebar__item--active": isActive,
                            })
                          }
                        >
                          <Icon icon={icon} className="fa-fw" />
                          <p>{t(title)}</p>
                        </NavLink>
                      </li>
                    ))}
                </SidebarGroup>
              ))}

          <SidebarGroup expanded={isExpanded} title={t("language.text")}>
            {supportLanguageList.map((language) => (
              <Button
                borderType="plain"
                className={cx("sidebar__item sidebar__cta", {
                  "sidebar__item--active": locale === language,
                })}
                key={language}
                onClick={() => setLocale(language)}
              >
                <p className="language-text">{language.toUpperCase()}</p>
                <p>{t(`language.${language}`)}</p>
              </Button>
            ))}
          </SidebarGroup>
        </div>

        <div className="bottom-container">
          {isLoggedIn && (
            <Button
              borderType="plain"
              icon={["fas", "sign-out-alt"]}
              iconClassName="fa-fw"
              className="sidebar__cta"
              onClick={() => logout()}
            >
              {t("common.action.logout")}
            </Button>
          )}

          {isLg && (
            <Button
              borderType="plain"
              icon={["fas", "chevron-left"]}
              iconClassName="fa-fw"
              className={cx("sidebar__cta sidebar__cta--expand-cta", {
                "sidebar__cta sidebar__cta--expand-cta--expanded": isExpanded,
              })}
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
