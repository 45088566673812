export const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/** Get duration object from millisecond */
export const getDuration = (
  timeInMilliseconds: number,
): { day: number; hour: number; minute: number; second: number } => {
  const day = Math.floor(timeInMilliseconds / (1000 * 60 * 60 * 24));
  const hour = Math.floor(
    (timeInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minute = Math.floor(
    (timeInMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
  );
  const second = Math.floor((timeInMilliseconds % (1000 * 60)) / 1000);

  return { day, hour, minute, second };
};

export const getMillisecondsFromMinutes = (minutes: number) =>
  minutes * 60 * 1000;
