import { library } from "@fortawesome/fontawesome-svg-core";
import * as fab from "@fortawesome/free-brands-svg-icons";
import * as far from "@fortawesome/pro-regular-svg-icons";
import * as fas from "@fortawesome/pro-solid-svg-icons";

export const initializeIconList = () => {
  const iconList = [
    fab.faFacebookF,
    fab.faGoogle,
    fab.faYelp,
    far.faAcorn,
    far.faAngleLeft,
    far.faAngleRight,
    far.faArrowDown19,
    far.faArrowDown91,
    far.faArrowDownAZ,
    far.faArrowDownSquareTriangle,
    far.faArrowDownTriangleSquare,
    far.faArrowDownZA,
    far.faArrowToLeft,
    far.faArrowToRight,
    far.faChevronDown,
    far.faInfoCircle,
    far.faPlus,
    far.faSort,
    far.faSpinner,
    fas.faArrowRight,
    fas.faBadgeCheck,
    fas.faBars,
    fas.faBellConcierge,
    fas.faBinarySlash,
    fas.faBuilding,
    fas.faBuildings,
    fas.faCalendar,
    fas.faChartLine,
    fas.faCheck,
    fas.faChevronLeft,
    fas.faCircleDollar,
    fas.faClipboard,
    fas.faClipboardListCheck,
    fas.faCreditCard,
    fas.faDeleteLeft,
    fas.faDollar,
    fas.faDollarSign,
    fas.faDownload,
    fas.faEarth,
    fas.faEllipsisVertical,
    fas.faEnvelope,
    fas.faExclamation,
    fas.faFaceDisappointed,
    fas.faFaceSmileBeam,
    fas.faFileInvoiceDollar,
    fas.faInfo,
    fas.faMagnifyingGlassMinus,
    fas.faMagnifyingGlassPlus,
    fas.faNotes,
    fas.faPersonRunning,
    fas.faPlay,
    fas.faQrcode,
    fas.faRegistered,
    fas.faRightToBracket,
    fas.faSearch,
    fas.faSignOutAlt,
    fas.faThoughtBubble,
    fas.faTimes,
    fas.faTrash,
    fas.faUser,
    fas.faUserGear,
    fas.faUserGear,
    fas.faUserGroup,
    fas.faUserPlus,
  ] as const;

  // Set up icon libraries
  // @ts-ignore
  library.add(...iconList);
};
