import cx from "classnames";
import { Icon } from "ducduchy-react-components";
import "./LoadingView.scss";

interface LoadingViewProps {
  overlay?: boolean;
}

export const LoadingView = ({ overlay }: LoadingViewProps) => {
  return (
    <div className={cx("loading-view", { "loading-view--overlay": overlay })}>
      {overlay && <div className="overlay" />}

      <Icon className="fa-fw fa-spin-pulse icon" icon={["far", "spinner"]} />
    </div>
  );
};
