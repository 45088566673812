import { queryClient } from "data/queries/client";
import { salonQueryKeys } from "hooks/queries/salon/query-key";
import { serviceToQueryFn } from "hooks/queries/util";
import { SalonDto } from "models/salon/salon.dto";
import { useQuery } from "react-query";
import { SalonService } from "services/apis/salon/salon.service";
import { APIErrorMessage } from "services/models/error";
import { useStoreState } from "stores";
import { getMillisecondsFromMinutes } from "utils/ui/common";

export const useGetSalonInfoQuery = () => {
  const salonId = useStoreState((state) => state.salon.salonId);

  return useQuery<SalonDto, APIErrorMessage>(
    salonQueryKeys.salonInfo(salonId!),
    async () => serviceToQueryFn(SalonService.getSalonInfo)(salonId!),
    {
      enabled: !!salonId,
      staleTime: getMillisecondsFromMinutes(10),
    },
  );
};

export const invalidateSalonInfoQuery = (salonId: string) =>
  queryClient.invalidateQueries(salonQueryKeys.salonInfo(salonId));
