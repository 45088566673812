import "ducduchy-react-components/dist/ducduchy-react-components.cjs.production.min.css";
import { useStoreRehydrated } from "easy-peasy";
import {
  useAuthManager,
  useI18nManager,
  useInit,
  useThemeManager,
} from "hooks";
import { AppStateManager } from "managers/AppStateManager";
import { RouteManager } from "managers/RouteManager";
import { Suspense } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "styles/main/App.scss";
import "styles/override/mobiscroll/MobiscrollCustomTheme.scss";
import { AppHeader } from "views/utils/AppHeader";
import { LoadingView } from "views/utils/LoadingView";
import { Sidebar } from "views/utils/Sidebar";

function App() {
  const isHydrated = useStoreRehydrated();
  useInit();

  useAuthManager();
  useThemeManager();
  useI18nManager();

  if (!isHydrated) {
    return <LoadingView />;
  }

  return (
    <BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />

      <AppStateManager />

      <ToastContainer
        position="top-right"
        toastClassName="toastify-container"
        bodyClassName="toastify-body"
        limit={4}
      />

      <div className="app">
        <Sidebar />

        <AppHeader />

        <div className="app-content">
          <Suspense fallback={<LoadingView />}>
            <RouteManager />
          </Suspense>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
