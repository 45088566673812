import { localeEn, localeVi, MbscLocale, setOptions } from "@mobiscroll/react";
import { SupportLanguage } from "data/i18n/i18n.data";
import dayjs from "dayjs";
import { action, Action, persist } from "easy-peasy";
import { customI18N } from "loaders/i18n.loader";

const DEFAULT_LANGUAGE: SupportLanguage = "en";
const mobiscrollLocale: Record<SupportLanguage, MbscLocale> = {
  vi: localeVi,
  en: localeEn,
};

export interface AccountModel {
  locale: SupportLanguage;
  isInCustomerMode: boolean;

  resetStore: Action<AccountModel>;

  initLocale: Action<AccountModel>;
  setLocale: Action<AccountModel, SupportLanguage>;
  toggleIsCustomerMode: Action<AccountModel>;
}

export const account: AccountModel = persist(
  {
    locale: DEFAULT_LANGUAGE,
    isInCustomerMode: false,

    resetStore: action((state) => {
      state.locale = DEFAULT_LANGUAGE;
      state.isInCustomerMode = false;
    }),

    // TODO: Decide whether to use location base
    initLocale: action((state) => {
      const curLocale = state.locale;
      customI18N.changeLanguage(curLocale);
      dayjs.locale(curLocale);
      setOptions({ locale: mobiscrollLocale[curLocale] });
    }),

    setLocale: action((state, locale) => {
      if (locale !== state.locale) {
        state.locale = locale;
        customI18N.changeLanguage(locale);
        dayjs.locale(locale);
        setOptions({ locale: mobiscrollLocale[locale] });
      }
    }),
    toggleIsCustomerMode: action((state) => {
      state.isInCustomerMode = !state.isInCustomerMode;
    }),
  },
  { allow: ["locale", "isInCustomerMode"], storage: "localStorage" },
);
