export const firebaseProdConfig = {
  apiKey: "AIzaSyDlIsw7NDnSgAKsZzr0K6RNl4YBgiHimFc",
  authDomain: "apheleia-saas-platform.firebaseapp.com",
  projectId: "apheleia-saas-platform",
  storageBucket: "apheleia-saas-platform.appspot.com",
  messagingSenderId: "215860181479",
  appId: "1:215860181479:web:36615778cbe1af8bf36542",
};

export const firebaseDevConfig = {
  apiKey: "AIzaSyABJ3JSTjq_Tib2KpboaSCgVq4ElMzigZg",
  authDomain: "apheleia-saas-platform--dev.firebaseapp.com",
  projectId: "apheleia-saas-platform--dev",
  storageBucket: "apheleia-saas-platform--dev.appspot.com",
  messagingSenderId: "229203442508",
  appId: "1:229203442508:web:ed8b1f135cd29ea94e6de9",
  measurementId: "G-G8LC42C8GT",
};
