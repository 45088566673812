import { useEffect } from "react";
import { useStoreActions, useStoreState } from "stores";
import { useGetSalonInfoQuery } from "./queries/salon/useGetSalonInfoQuery";
import { useQueryMatch } from "./useQueryMatch";

export const useSyncSalonId = () => {
  const [localSalonId, , setLocalSalonId] = useQueryMatch("currentSalonId");

  const storeSalonId = useStoreState((state) => state.salon.salonId);
  useEffect(() => {
    setLocalSalonId(storeSalonId);
  }, [storeSalonId, setLocalSalonId]);

  const setSalonId = useStoreActions((actions) => actions.salon.setSalonId);
  useEffect(() => {
    if (localSalonId) {
      setSalonId(localSalonId);
    }
  }, [localSalonId, setSalonId]);

  const { data: salon } = useGetSalonInfoQuery();
  const setSalonTier = useStoreActions((actions) => actions.salon.setSalonTier);
  useEffect(() => {
    if (salon) {
      setSalonTier(salon.salonTier);
    }
  }, [salon, setSalonTier]);
};
