import { APIErrorMessage } from "services/models/error";
import { ServiceResult } from "services/models/service";

export const serviceToQueryFn =
  <ResultData, ResultError extends APIErrorMessage, Args extends unknown[]>(
    argFunc: (...args: Args) => Promise<ServiceResult<ResultData, ResultError>>,
  ) =>
  async (...args: Args) => {
    const { error, result } = await argFunc(...args);

    if (error) {
      throw error.message;
    }

    return result!;
  };
