import { SalonRole } from "data/admin/app-custom-claims";
import {
  baseRouteDataMap,
  miscRoute,
  routeBasePath,
} from "data/navigation/route-list";
import { useWatchAppointmentMetadata } from "hooks/queries/appointment/useWatchAppointmentMetadata";
import {
  RouteAuthGuard,
  checkUserHasPermissionToRoute,
} from "models/auth/auth-guard";
import { UserAuthState } from "models/auth/auth-state";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useStoreState } from "stores";

interface RedirectState {
  from: string;
  search: string;
  authGuardList: readonly RouteAuthGuard[];
}

export const getDefaultPath = (
  authState: UserAuthState,
  userSalonRole: SalonRole | null,
) => {
  if (authState.type === "loggedOut") {
    return routeBasePath.login;
  }

  if (!userSalonRole) {
    return routeBasePath.mySalons;
  }

  return userSalonRole === "employee"
    ? routeBasePath.scheduling
    : routeBasePath.mySalons;
};

export const RouteManager = () => {
  const userAuthState = useStoreState((state) => state.auth.userAuthState);
  const userSalonRole = useStoreState((state) => state.salon.salonRole);
  const salonTier = useStoreState((state) => state.salon.salonTier);
  const salonId = useStoreState((state) => state.salon.salonId);

  const location = useLocation();
  const curRedirectState = location.state as RedirectState | null;

  useWatchAppointmentMetadata(salonId);

  return (
    <Routes>
      {Object.keys(baseRouteDataMap).map((routeKey) => {
        const pathKey = routeKey as keyof typeof baseRouteDataMap;
        const path = routeBasePath[pathKey];
        const { Component, authGuardList } = baseRouteDataMap[pathKey];

        let element: JSX.Element | undefined = undefined;

        // * Redirect to previous redirect state
        if (
          curRedirectState &&
          checkUserHasPermissionToRoute({
            userAuthState,
            userSalonRole,
            salonTier,
            routeAuthGuardList: curRedirectState.authGuardList,
          }) &&
          // Either logged out or have chosen a salon
          (userAuthState.type === "loggedOut" || userSalonRole)
        ) {
          element = (
            <Navigate
              replace
              to={{
                pathname: curRedirectState.from,
                search: curRedirectState.search,
              }}
            />
          );
        }

        // * Redirect based on unsatisfied auth requirement

        if (!element) {
          const needRedirect = !checkUserHasPermissionToRoute({
            userAuthState,
            userSalonRole,
            salonTier,
            routeAuthGuardList: authGuardList,
          });

          // Still do this in case redirect state still not satisfied
          if (!needRedirect) {
            element = <Component />;
          } else {
            const redirectState: RedirectState = {
              from: location.pathname,
              search: location.search,
              authGuardList: authGuardList,
            };

            const defaultPathToRedirect = getDefaultPath(
              userAuthState,
              userSalonRole,
            );

            element = (
              <Navigate
                replace
                to={defaultPathToRedirect}
                state={redirectState}
              />
            );
          }
        }

        return <Route key={path} path={path} element={element} />;
      })}

      <Route path="/uc" element={<miscRoute.uc.Component />} />

      <Route
        path="/"
        element={
          <Navigate replace to={getDefaultPath(userAuthState, userSalonRole)} />
        }
      />
    </Routes>
  );
};
